import React, { useCallback, useEffect, useMemo } from 'react';
import { AreaInfo } from '@apolloCli/queries/areas';
import Link from 'next/link';
import clsx, { ClassValue } from 'clsx';
import { Modal } from '@components/communication/Modal/Modal';
import { Button } from '@components/general/Button/Button';
import { ParentChildAreas } from '@apolloCli/queries/accountFlow';
import { Areas } from '@apolloCli/queries/activeOrders';
import { AdvancedSearchArea, useAdvanceSearch } from '@hooks/useAdvanceSearch';
import { MarketResponse } from '@hooks/useCustomMarket';
import { UpsertCompetitorGroup } from '@hooks/useCompetitorSet';
import { CONTACT_US_WEBAPP } from '@utils/links';

import { useAdvancedSearchParameters } from './AdvancedSearchBar.logic';
import { AreaList, ListNames, RelatedAreas } from './RelatedAreas/RelatedAreas';
import { RecentSearches } from './RecentSearches/RecentSearches';
import { PremiumMarkets } from './PremiumMarkets/PremiumMarkets';
import { MyComps } from './MyComps/MyComps';
import { SavedMarkets } from './SavedMarkets/SavedMarkets';
import styles from './AdvancedSearchBar.module.scss';

type Props = {
  data?: AreaList[];
  currentArea?: AreaInfo;
  onClickArea: (event: AreaList) => void;
  className?: ClassValue;
  onClose: () => void;
  isOpen: boolean;
  hasChildAreas?: boolean;
  hasParentAreas?: boolean;
  parentAreas?: ParentChildAreas[];
  childCount: number;
  afterClickItem?: () => void;
  emptyByDefault?: boolean;
  cursor?: number;
  setItemLenghts: any;
  isActivateOnClick?: boolean;
};

export const AdvancedSearchBar: React.FC<Props> = ({
  data,
  currentArea,
  onClickArea,
  isOpen,
  onClose,
  className,
  hasChildAreas,
  hasParentAreas,
  parentAreas,
  afterClickItem = () => {},
  emptyByDefault = false,
  cursor,
  setItemLenghts,
  isActivateOnClick = false,
}) => {
  const areaName = currentArea?.fullName?.split(',').slice(0, -1).join(',');
  const dataSorted = data?.slice().sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  const maxListing = parentAreas && Math.max(...parentAreas.map((o) => o.listingCount));
  const lastParent = parentAreas && parentAreas.find((parent) => parent.listingCount === maxListing);
  const { onClickMarket, onClickComp, showComps, showSavedAreas, multipleClickActions } = useAdvancedSearchParameters();
  const {
    recentViewedAreas,
    customMarkets,
    purchasedMarkets,
    loadingCustomMarkets,
    loadingPurchasedMarkets,
    compSets,
    loadingComps,
  } = useAdvanceSearch();

  const advanceSearchResults = useMemo(() => {
    let position = 0;
    const addPositionToList = (list: Array<Record<string, any>> | undefined, listName?: ListNames) =>
      list?.map((item) => {
        const newItem = { ...item, position, listName };
        position += 1;
        return newItem;
      });
    return {
      recentViewedAreas: addPositionToList(recentViewedAreas, ListNames.RECENT_VIEWS),
      relatedResults: addPositionToList(dataSorted, ListNames.RELATED_RESULTS) as AreaList[],
      myMarkets: addPositionToList(purchasedMarkets, ListNames.MY_MARKETS) as unknown as Areas[],
      compSets: addPositionToList(compSets, ListNames.COMPS) as UpsertCompetitorGroup[],
      savedSearches: addPositionToList(customMarkets, ListNames.SAVED_SEARCHES) as MarketResponse[],
      totalLenght: position,
    };
  }, [compSets, customMarkets, dataSorted, purchasedMarkets, recentViewedAreas]);

  const hasComps = compSets && compSets.length > 0;
  const hasPremiumMarkets = purchasedMarkets && purchasedMarkets.length > 0;
  const hasCustomMarkets = customMarkets && customMarkets.length > 0;
  const hasMarkets = hasPremiumMarkets || hasCustomMarkets;
  const hasRecentSearches = recentViewedAreas.length > 0;
  const hasRelatedAreas = data && data.length > 0;

  const onClickRelated = (value: AreaList) => {
    onClickArea(value);
  };

  const onClickMarketItem = (area?: AdvancedSearchArea | Areas) => {
    onClickMarket(area?.areaId);
    afterClickItem();
  };

  const onClickCustomMarketItem = (area?: MarketResponse) => {
    onClickMarket(area?.areaId, area?.id);
    afterClickItem();
  };

  const onClickCustomComp = (comp?: UpsertCompetitorGroup) => {
    onClickComp(comp);
    afterClickItem();
  };

  const advanceSearchEnterKeyAction = useCallback(() => {
    const keys = Object.keys(advanceSearchResults);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      if (key !== 'totalLenght') {
        const element = (advanceSearchResults[key as keyof typeof advanceSearchResults] as Array<any>).find(
          (item: { position: number }) => item.position === cursor,
        );
        multipleClickActions(element, onClickArea);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceSearchResults, multipleClickActions]);

  useEffect(() => {
    if (setItemLenghts) {
      setItemLenghts({
        recentViewsLenght: advanceSearchResults?.recentViewedAreas?.length || 0,
        relatedAreasLenght: advanceSearchResults?.relatedResults?.length || 0,
        myMarketsLengh: advanceSearchResults?.myMarkets?.length || 0,
        compsLenght: advanceSearchResults?.compSets?.length || 0,
        saveSearchesLenght: advanceSearchResults?.savedSearches?.length || 0,
        totalLenght: advanceSearchResults?.totalLenght,
      });
    }
  }, [
    advanceSearchResults?.compSets?.length,
    advanceSearchResults?.myMarkets?.length,
    advanceSearchResults?.recentViewedAreas?.length,
    advanceSearchResults?.relatedResults?.length,
    advanceSearchResults?.savedSearches?.length,
    advanceSearchResults?.totalLenght,
    setItemLenghts,
  ]);

  useEffect(() => {
    if (isActivateOnClick) {
      advanceSearchEnterKeyAction();
    }
  }, [isActivateOnClick, advanceSearchEnterKeyAction]);

  if (!hasRecentSearches && !hasMarkets && !hasComps && !hasRelatedAreas) return null;

  return (
    <div className={clsx(className)}>
      <div className={styles.Container}>
        <div className={styles.Desktop} data-test-id="advanced-search-bar-desktop">
          {(hasRecentSearches || hasRelatedAreas) && (
            <div className={styles.LeftSection}>
              {hasRecentSearches && (
                <RecentSearches
                  data={advanceSearchResults?.recentViewedAreas}
                  onClickItem={onClickMarketItem}
                  cursor={cursor}
                />
              )}
              {hasRelatedAreas && (
                <RelatedAreas
                  hasChildAreas={hasChildAreas}
                  data={advanceSearchResults?.relatedResults}
                  lastParent={lastParent}
                  hasParentAreas={hasParentAreas}
                  parentAreas={parentAreas}
                  onClick={onClickRelated}
                  areaName={areaName}
                  cursor={cursor}
                />
              )}
            </div>
          )}

          {(hasCustomMarkets || hasPremiumMarkets || hasComps) && (
            <div className={styles.RightSection}>
              {hasPremiumMarkets && (
                <PremiumMarkets
                  premiumMarkets={advanceSearchResults?.myMarkets}
                  onClickMarketItem={onClickMarketItem}
                  loading={loadingPurchasedMarkets}
                  cursor={cursor}
                />
              )}
              {(hasCustomMarkets || hasComps) && (
                <div className={styles.CustomMarketsAndComps}>
                  {hasComps && showComps && (
                    <MyComps
                      data={advanceSearchResults?.compSets}
                      loading={loadingComps}
                      onClickItem={onClickCustomComp}
                      cursor={cursor}
                    />
                  )}
                  {hasCustomMarkets && showSavedAreas && (
                    <SavedMarkets
                      customMarkets={advanceSearchResults?.savedSearches}
                      onClickCustomMarketItem={onClickCustomMarketItem}
                      loading={loadingCustomMarkets}
                      cursor={cursor}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.Mobile}>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            className={styles.ModalBox}
            modalClassName={styles.Mobile}
            footer={
              <div className={styles.DoneButton}>
                <span>
                  <Link href={CONTACT_US_WEBAPP} target="_blank">
                    CLICK HERE
                  </Link>
                  to inquire about a Custom Area.
                </span>

                <Button onClick={onClose} type="button" theme="lightBlue">
                  Done
                </Button>
              </div>
            }
          >
            <div data-test-id="advanced-search-bar-mobile">
              {!emptyByDefault && (
                <div className={styles.CurrentCityMobile}>
                  <span>Current City,</span>
                  <h4>{areaName}</h4>
                </div>
              )}
              {hasRecentSearches && <RecentSearches data={recentViewedAreas} onClickItem={onClickMarketItem} />}
              {hasPremiumMarkets && (
                <PremiumMarkets
                  premiumMarkets={purchasedMarkets}
                  onClickMarketItem={onClickMarketItem}
                  loading={loadingPurchasedMarkets}
                />
              )}

              {hasComps && showComps && (
                <MyComps data={compSets} loading={loadingComps} onClickItem={onClickCustomComp} />
              )}

              {hasCustomMarkets && showSavedAreas && (
                <SavedMarkets
                  customMarkets={customMarkets}
                  onClickCustomMarketItem={onClickCustomMarketItem}
                  loading={loadingCustomMarkets}
                />
              )}
              {hasRelatedAreas && (
                <RelatedAreas
                  hasChildAreas={hasChildAreas}
                  data={dataSorted}
                  lastParent={lastParent}
                  hasParentAreas={hasParentAreas}
                  parentAreas={parentAreas}
                  onClick={onClickRelated}
                  areaName={areaName}
                />
              )}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import Link from 'next/link';
import clsx, { ClassValue } from 'clsx';
import { AreaInfo } from '@apolloCli/queries/areas';
import { ParentChildAreas } from '@apolloCli/queries/accountFlow';
import { CountActive } from '@apolloCli/queries/nearbyAreas';
import { Modal } from '@components/communication/Modal/Modal';
import { Button } from '@components/general/Button/Button';
import { useAreaInfo } from '@hooks/useAreaInfo';
import { CONTACT_US_WEBAPP } from '@utils/links';

import styles from './AutoCompleteDropDownList.module.scss';

type AreaList = {
  id: number;
  name: string;
  nListings: number;
  countActive?: CountActive[];
};

type Props = {
  data?: AreaList[];
  currentArea?: AreaInfo;
  onClickArea: (event: AreaList) => void;
  className?: ClassValue;
  onClose: () => void;
  openNearbyAreas: boolean;
  hasChildAreas?: boolean;
  hasParentAreas?: boolean;
  parentAreas?: ParentChildAreas[];
  childCount: number;
};

type ListProps = {
  hasChildAreas?: boolean;
  areaInfo?: AreaInfo;
  childCount: number;
  dataSorted?: AreaList[];
  lastParent?: ParentChildAreas;
  hasParentAreas?: boolean;
  parentAreas?: ParentChildAreas[];
  areaName?: string;
  onClick: (value: AreaList) => void;
};

const formatNumber = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const List: React.FC<ListProps> = ({
  hasChildAreas,
  areaInfo,
  childCount,
  dataSorted,
  lastParent,
  hasParentAreas,
  parentAreas,
  areaName,
  onClick,
}) => (
  <div className={styles.Container}>
    <div className={clsx(styles.Table)}>
      <table>
        <thead>
          {!hasChildAreas ? (
            <tr className={styles.ListLabels}>
              <th className={styles.TitleTable}>Related Results</th>
              <th className={styles.ListTitleTable}># of listings</th>
            </tr>
          ) : (
            <tr className={styles.ListLabels}>
              <th className={styles.TitleTableLight}>
                <div className={styles.Icon}>
                  <svg width="8.357" height="11.938" viewBox="0 0 8.357 11.938">
                    <path
                      // eslint-disable-next-line max-len
                      d="M11.678,3A4.175,4.175,0,0,0,7.5,7.178c0,3.134,4.178,7.76,4.178,7.76s4.178-4.626,4.178-7.76A4.175,4.175,0,0,0,11.678,3Zm0,5.671a1.492,1.492,0,1,1,1.492-1.492A1.493,1.493,0,0,1,11.678,8.671Z"
                      transform="translate(-7.5 -3)"
                      fill="#959595"
                    />
                  </svg>
                </div>
                Areas in <span>{areaInfo?.name}</span>
                <div className={styles.Light}>
                  <span>{childCount} </span>
                  Neighborhoods/zip codes
                </div>
              </th>
              <th className={styles.ListTitleTable}># of listings</th>
            </tr>
          )}
        </thead>
        <tbody>
          {dataSorted
            ?.sort((x, y) => {
              if ((x.id === lastParent?.areaId) === (y.id === lastParent?.areaId)) {
                return 0;
              }
              if (x) {
                return -1;
              }
              return 1;
            })
            .map((area) => (
              <tr onClick={() => onClick(area)} className={styles.List} key={area.id}>
                <td>
                  {area.name} {lastParent?.areaId === area.id && <i className={styles.Light}>- Includes {areaName}</i>}
                </td>
                <td>{formatNumber(area.nListings || 0)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {hasParentAreas && parentAreas && hasChildAreas && (
        <div className={styles.RelatedResultsWrapper}>
          <div className={styles.RelatedResults}>Related Results</div>
          <table>
            <tbody>
              <tr
                onClick={() =>
                  onClick({
                    id: lastParent?.areaId || 0,
                    name: lastParent?.areaName || '',
                    nListings: lastParent?.listingCount || 0,
                  })
                }
                className={styles.List}
              >
                <td>{lastParent?.areaName}</td>
                <td>{formatNumber(lastParent?.listingCount || 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
    {!dataSorted?.length && (
      <div className={styles.NotAreaMessage}>
        <p>
          Please use the main search-bar to navigate global markets.
          <br />
          If there is something missing, please
          <Link href={CONTACT_US_WEBAPP} target="_blank">
            let us know!
          </Link>
        </p>
      </div>
    )}
  </div>
);

export const AutoCompleteDropDownList: React.FC<Props> = ({
  data,
  currentArea,
  onClickArea,
  openNearbyAreas,
  onClose,
  className,
  hasChildAreas,
  childCount,
  hasParentAreas,
  parentAreas,
}) => {
  // Const
  const areaName = currentArea?.fullName?.split(',').slice(0, -1).join(',');
  const dataSorted = data?.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
  const maxListing = parentAreas && Math.max(...parentAreas.map((o) => o.listingCount));
  const lastParent = parentAreas && parentAreas.find((parent) => parent.listingCount === maxListing);
  const { areaInfo } = useAreaInfo({ areaId: currentArea?.id });

  const onClick = (value: AreaList) => {
    onClickArea(value);
  };

  return (
    <div className={clsx(className)}>
      <div className={styles.Desktop}>
        <List
          hasChildAreas={hasChildAreas}
          areaInfo={areaInfo}
          childCount={childCount}
          dataSorted={dataSorted}
          lastParent={lastParent}
          hasParentAreas={hasParentAreas}
          parentAreas={parentAreas}
          areaName={areaName}
          onClick={onClick}
        />
      </div>
      <div className={styles.Mobile}>
        <Modal
          isOpen={openNearbyAreas}
          onClose={onClose}
          className={styles.ModalBox}
          modalClassName={styles.Mobile}
          footer={
            <div className={styles.DoneButton}>
              <span>
                <Link href={CONTACT_US_WEBAPP} target="_blank">
                  CLICK HERE
                </Link>
                to inquire about a Custom Area.
              </span>

              <Button onClick={onClose} type="button" theme="lightBlue">
                Done
              </Button>
            </div>
          }
        >
          <div>
            <div className={styles.CurrentCityMobile}>
              <span>Current City,</span>
              <h4>{areaName}</h4>
            </div>
            <List
              hasChildAreas={hasChildAreas}
              areaInfo={areaInfo}
              childCount={childCount}
              dataSorted={dataSorted}
              lastParent={lastParent}
              hasParentAreas={hasParentAreas}
              parentAreas={parentAreas}
              areaName={areaName}
              onClick={onClick}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

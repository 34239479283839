import { useEffect, useState } from 'react';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { Areas, PlanScopeEnum } from '@apolloCli/queries/activeOrders';
import { ListNames } from '@components/dataEntry/AdvancedSearchBar/RelatedAreas/RelatedAreas';

import { CustomFilters } from './useUiFilters';
import { useCustomMarket } from './useCustomMarket';
import { useCompetitorSet } from './useCompetitorSet';

const KEY = 'recentView';

export type AdvancedSearchArea = {
  areaId?: number;
  text?: string;
  payload?: {
    center?: [number, number];
  };
  position?: number;
  listName?: ListNames;
};

export type CustomMarket = {
  id: number;
  name: string;
  areaId: number;
  areaName: string;
  filters: CustomFilters;
};

const sortByLastPurchased = (areas?: Areas[]) => {
  const areasCopy = areas ?? [];
  return [...areasCopy]
    ?.sort((a: Areas, b: Areas) => {
      if (a.firstTargetDate && b.firstTargetDate) {
        return new Date(b.firstTargetDate ).getTime() - new Date(a.firstTargetDate).getTime();
      }
      return 0;
    })
    .slice(0, 3);
};

export const useAdvanceSearch = () => {
  const [recentView, setRecentView] = useState<AdvancedSearchArea[]>([]);
  const { activeOrdersV2, isReady } = useAreaActiveOrdersV2();
  const marketScopeAreas = activeOrdersV2?.areas.filter((area) => area.planScope === PlanScopeEnum.MARKET);
  const loadingPurchasedMarkets = !isReady;

  const { customMarketsData, loading: loadingCustomMarkets } = useCustomMarket({
    getAllMarkets: true,
    fetchPolicy: 'cache-first',
    isAdvanceSearch: true,
  });
  const { compGroupData, loading: loadingComps } = useCompetitorSet({
    getAllComps: true,
    fetchPolicy: 'cache-first',
    isAdvanceSearch: true,
  });
  const compsCopy = compGroupData?.competitorGroups ?? [];
  const customMarketsCopy = customMarketsData?.customMarkets ?? [];
  const compSets = [...compsCopy].sort((a, b) => b.competitorGroupId - a.competitorGroupId)?.slice(0, 3);
  const customMarkets = [...customMarketsCopy].sort((a, b) => b.id - a.id).slice(0, 3);

  const handleRecentView = (search: AdvancedSearchArea) => {
    let currentArray = [search, ...recentView];
    if (recentView.length > 0) {
      const inArray = recentView.find((recent) => recent.areaId === search.areaId);
      if (inArray) {
        currentArray = recentView;
      }
    }
    if (currentArray.length > 4) {
      currentArray.pop();
    }
    setRecentView(currentArray);
  };

  const clearRecent = () => {
    setRecentView([]);
  };

  useEffect(() => {
    const recentViewString = localStorage.getItem(KEY);
    if (recentViewString) {
      const recentViewParse = JSON.parse(recentViewString) as AdvancedSearchArea[];
      if (recentViewParse) {
        setRecentView(recentViewParse);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(KEY, JSON.stringify(recentView));
  }, [recentView]);

  return {
    recentViewedAreas: recentView.slice(1, 4),
    purchasedMarkets: sortByLastPurchased(marketScopeAreas) || [],
    handleRecentView,
    clearRecent,
    customMarkets,
    loadingPurchasedMarkets,
    loadingCustomMarkets,
    compSets,
    loadingComps,
  };
};

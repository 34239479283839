/* eslint-disable max-len */
import React from 'react';

export const SavedSearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.39 113.2" width={20} height={20}>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          className="cls-1"
          d="M106.56,113.2,65.41,71.68a34.12,34.12,0,0,1-11,6.28,38,38,0,0,1-13.56,2.21A39.5,39.5,0,0,1,12,68.27C4.15,60.43,0,50.93,0,40A38.87,38.87,0,0,1,11.9,11.62,39.2,39.2,0,0,1,40.5,0,37.53,37.53,0,0,1,68.82,12c7.85,8.21,11.63,17.26,11.63,28.33a36.38,36.38,0,0,1-2.21,12.91,47.64,47.64,0,0,1-6.65,11.9l41.8,41.15ZM40.59,71a29.64,29.64,0,0,0,21.68-9.13A29.31,29.31,0,0,0,71,40.22a29.65,29.65,0,0,0-8.77-21.68A29.19,29.19,0,0,0,40.59,9.41a30.5,30.5,0,0,0-22.05,9.13A29.67,29.67,0,0,0,9.41,40.22a29.67,29.67,0,0,0,9.13,21.69A30.5,30.5,0,0,0,40.59,71ZM28.88,56.92l4.7-13.84L22,34.32H35.8l4.7-13.56,4.71,13.56H58.77L47.14,43.08l4.71,13.84L40.5,48.16Z"
        />
      </g>
    </g>
  </svg>
);

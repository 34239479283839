import React, { useRef, useState, useEffect, useCallback } from 'react';
import clsx, { ClassValue } from 'clsx';
import { useReactiveVar } from '@apollo/client';
import { AreaInfo } from '@apolloCli/queries/areas';
import { NearbyArea } from '@apolloCli/queries/nearbyAreas';
import { setOverlay, showAccountFlowPopOverVar } from '@apolloCli/policies/uiPolicy';
import { AutoCompleteListItems, AutoCompleteOption } from '@components/dataEntry/AutoComplete/AutoCompleteListItems';
import { AreaObject } from '@components/dataEntry/AreaAutocomplete/AreaAutocomplete.logic';
import { PopOver } from '@components/communication/PopOver/PopOver';
import { AutoCompleteDropDownList } from '@components/dataEntry/AutoCompleteDropDownList/AutoCompleteDropDownList';
import { AdvancedSearchBar } from '@components/dataEntry/AdvancedSearchBar/AdvancedSearchBar';
import { Button } from '@components/general/Button/Button';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';
import { MagnifyingGlassIcon } from '@components/iconography/MagnifyingGlass/MagnifyingGlassIcon';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';
import { useChildAreas } from '@hooks/useChildAreas';
import { useParentAreas } from '@hooks/useParentAreas';
import { useUser } from '@hooks/useUser';
import { useUiFilters } from '@hooks/useUiFilters';
import useBreakpoint from '@utils/useBreakpoint';

import styles from './AutoComplete.module.scss';

type Props<OptionType, SuggestedOptionType, PickedSuggesteOptionType> = {
  isLoading?: boolean;
  limitOptions?: number;
  startLen?: number;
  options: Array<string> | Array<AutoCompleteOption<OptionType>>;
  onChange: (newValue: string) => void;
  onClear?: () => void;
  onSelect: (option: AutoCompleteOption<OptionType> | null) => void;
  className?: ClassValue;
  text?: string;
  SuggestionList: SuggestionList<SuggestedOptionType, PickedSuggesteOptionType>;
  suggestedOptions: SuggestionListProps<SuggestedOptionType, PickedSuggesteOptionType>['options'];
  onSelectSuggestion: (
    picked: Parameters<SuggestionListProps<SuggestedOptionType, PickedSuggesteOptionType>['onClickItem']>[0],
  ) => void;
  hideItemsAfterPick?: boolean;
  setInputValueAfterPick?: boolean;
  nearbyAreasData?: Omit<NearbyArea, 'countActive'>[];
  currentArea?: AreaInfo;
  onClickArea: (event: NearbyArea) => void;
  emptyByDefault?: boolean;
  hideChevron?: boolean;
  placeholder?: string;
};

type SuggestionListProps<O, P> = {
  // TODO: remove AreaObject & AreaInfo depenencies
  // This is a temporary fix because "O extends never" does not resolve properly for both
  // GeocodedAutocomplete and AreaAutocomplete's SuggestionList
  options: O extends AreaObject | AreaInfo ? Array<AutoCompleteOption<O>> : Array<string>;
  onClickItem: (picked: AutoCompleteOption<P>) => void;
  textSearched: string;
  cursor: number;
};

type SuggestionList<O, P> = React.FC<SuggestionListProps<O, P>>;

export const FullChevron: React.FC<{ onClick?: () => void; dataTestId?: string }> = ({ onClick, dataTestId }) => (
  <div className={styles.NearbyAreasButton} onClick={onClick} data-test-id={dataTestId}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6">
      <path data-name="Polygon 12" d="M6 6L0 0h12z" fill="#777b81" />
    </svg>
  </div>
);

export const ClearIcon: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div className={styles.CloseIcon} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="8.911" height="8.911" viewBox="0 0 8.911 8.911">
      <g transform="translate(-1210.567 843.084) rotate(-45)">
        <line y1="11.302" transform="translate(1452.151 260.5)" fill="none" stroke="#1477fc" strokeWidth="1.3" />
        <line x2="11.302" transform="translate(1446.5 266.151)" fill="none" stroke="#1477fc" strokeWidth="1.3" />
      </g>
    </svg>
  </div>
);

const timersId: { [key: string]: any } = {
  focus: null,
  debounce: null,
};

export const AutoComplete2 = <O, SO, PO>({
  options,
  limitOptions,
  onChange,
  onSelect,
  onClear,
  isLoading = false,
  className,
  text,
  SuggestionList,
  suggestedOptions,
  onSelectSuggestion,
  hideItemsAfterPick = true,
  setInputValueAfterPick = true,
  nearbyAreasData,
  currentArea,
  onClickArea,
  emptyByDefault = false,
  hideChevron = false,
  placeholder,
}: Props<O, SO, PO>) => {
  const [inputValue, setInputValue] = useState('');
  const [isSuggestedOpen, setIsSuggestedOpen] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showAdvancedBar, setShowAdvancedBar] = useState(false);
  const { isLogin } = useUser();
  const showAccountFlowPopOver = useReactiveVar(showAccountFlowPopOverVar);
  const [areasList, setAreasList] = useState<{ id: number; name: string; nListings: number }[]>();
  const [selected, setSelected] = useState<AutoCompleteOption<O | PO>>();
  const showPopUp = showAccountFlowPopOver;
  const [focus, setFocus] = useState(false);
  const [openNearbyAreas, setOpenNearbyAreas] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [itemLenghts, setItemLenghts] = useState({
    recentViewsLenght: 0,
    relatedAreasLenght: 0,
    myMarketsLengh: 0,
    compsLenght: 0,
    saveSearchesLenght: 0,
    totalLenght: 0,
  });
  const [isActivateOnClick, setIsActivateOnClick] = useState(false);
  const limit = limitOptions || options.length;
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const breakPoint = useBreakpoint();
  const { childAreasData, childAreasLoading } = useChildAreas({ areaId: currentArea?.id });
  const { parentAreasData, parentAreasLoading } = useParentAreas({ areaId: currentArea?.id });
  const loadingHerarchy = parentAreasLoading || childAreasLoading;
  const hasChildAreas = childAreasData && childAreasData.childAreas.length > 0;
  const hasParentAreas = parentAreasData && parentAreasData.parentAreas.length > 0;
  const isMobile = breakPoint === 'mobile';
  const { providerId } = useUiFilters();
  useOutsideAlerter(autocompleteRef, () => {
    setShowItems(false);
    setOverlay(false);
    if (!isMobile) {
      setOpenNearbyAreas(false);
      setShowAdvancedBar(false);
    }
  });

  const maxLenght = showAdvancedBar
    ? itemLenghts.totalLenght - 1
    : suggestedOptions.length
    ? suggestedOptions.length - 1
    : options.length - 1;

  const compsFirstPosition =
    itemLenghts.relatedAreasLenght + itemLenghts.myMarketsLengh + itemLenghts.recentViewsLenght;

  // update input with the outside text param
  if (text && !showItems && !focus && (!inputValue?.length || inputValue !== text)) {
    setTimeout(() => {
      setInputValue(text);
      onChange(text);
    });
  }

  const filteredOptions = [...options].slice(0, limit).map((option) => {
    if (typeof option === 'string') {
      return {
        text: option,
      } as AutoCompleteOption<O>; // type O is unknown for all items
    }

    return option;
  });

  const optionPointedByCursor = filteredOptions[cursor];
  const suggestedOptionPointedByCursor = suggestedOptions[cursor];

  const updateFocus = (newValue: boolean, time: number) => {
    if (timersId.focus) {
      clearTimeout(timersId.focus);
      timersId.focus = null;
    }

    if (newValue) {
      setFocus(true);
    } else {
      timersId.focus = setTimeout(() => {
        setFocus(false);
      }, time);
    }
  };

  const clearInput = () => {
    setInputValue('');
    onChange(''); // hack: onChangeText won't trigger when the input is cleared
    setShowItems(false);
    onSelect(null);
    updateFocus(true, 200);
    const element: any = inputRef && inputRef.current;

    if (element) {
      element.focus();
    }
    if (onClear) {
      onClear();
      setCursor(0);
    }
  };

  const onClickOpenAdvanced = () => {
    showAccountFlowPopOverVar(false);
    if (!loadingHerarchy) {
      setShowAdvancedBar(true);
    }
  };

  const onChangeText = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setCursor(0);

    if (timersId.focus) {
      clearTimeout(timersId.focus);
      timersId.focus = null;
    }

    setFocus(true);

    if (!newValue.length) {
      setShowItems(false);
    }

    if (focus && newValue.length) {
      if (newValue.length) {
        setShowItems(true);
      } else {
        setShowItems(false);
      }
    }

    timersId.debounce = window.setTimeout(() => {
      onChange(newValue);
    }, 500);
  };

  const toggleShowItems = (value: boolean) => () => {
    if (inputValue !== text) {
      setShowItems(value);
    }

    updateFocus(true, 200);
    if (inputRef && inputRef.current) {
      inputRef.current.select();
    }
  };

  const pickItem = useCallback(
    (option: AutoCompleteOption<O>) => {
      if (timersId.focus) {
        clearTimeout(timersId.focus);
        timersId.focus = null;
      }

      timersId.focus = setTimeout(() => {
        setFocus(false);
      }, 1000);

      if (setInputValueAfterPick) {
        setInputValue(option.text);
      }

      if (hideItemsAfterPick) {
        setShowItems(false);
      }

      setSelected(option);
      onSelect(option);
      setCursor(0);
    },
    [hideItemsAfterPick, onSelect, setInputValueAfterPick],
  );

  const pickNearbyArea = useCallback(
    (area: NearbyArea) => {
      setInputValue(area.name);
      setOpenNearbyAreas(false);
      setShowAdvancedBar(false);
      onClickArea(area);
      setCursor(0);
    },
    [onClickArea],
  );

  const pickSuggestionItem = useCallback(
    (suggestion: AutoCompleteOption<PO>) => {
      setSelected(suggestion);
      onSelectSuggestion(suggestion);
      if (hideItemsAfterPick) {
        setShowItems(false);
      }
      setFocus(false);
      setCursor(0);
    },
    [hideItemsAfterPick, onSelectSuggestion],
  );

  const closeFeaturePopup = () => {
    showAccountFlowPopOverVar(false);
  };

  const setTitle = () => {
    if (showPopUp) {
      return (
        <div className={styles.PopOver}>
          <span className={styles.PopOverTitle}>
            <img src="/images/icons/yellow-stars.svg" /> New
          </span>
          <p className={styles.Bold}>Find areas included in your subscription</p>
          <p>Access all the areas included in your Premium Plan - all in one click</p>
          <div className={styles.ButtonWrapper}>
            <Button type="button" theme="lightBlue" onClick={closeFeaturePopup}>
              Got it!
            </Button>
          </div>
        </div>
      );
    }
    if (loadingHerarchy) {
      return <LogoDoors size="m" loop />;
    }
    if (hasChildAreas) {
      return 'Included Areas';
    }
    return 'Nearby Areas';
  };

  const handleInputClick = () => {
    if ((currentArea?.fullName === inputValue || emptyByDefault) && !isMobile) {
      onClickOpenAdvanced();
    }
  };

  const closeSearchBarResults = () => {
    setOpenNearbyAreas(false);
    setShowAdvancedBar(false);
    setCursor(0);
  };

  const openSearchBarResults = () => {
    if (!showAdvancedBar) {
      onClickOpenAdvanced();
      setOverlay(true);
    }
  };

  const arrowDown = useCallback(
    (event: any) => {
      if (event.key === 'ArrowDown' && cursor < maxLenght) {
        setCursor(cursor + 1);
      }
    },
    [cursor, maxLenght],
  );

  const arrowUp = useCallback(
    (event: any) => {
      if (event.key === 'ArrowUp' && cursor > 0) {
        setCursor(cursor - 1);
      }
    },
    [cursor],
  );

  const arrowRight = useCallback(
    (event: any) => {
      if (event.key === 'ArrowRight' && showAdvancedBar) {
        if (cursor < compsFirstPosition) {
          setCursor(itemLenghts.relatedAreasLenght + itemLenghts.recentViewsLenght);
        } else {
          setCursor(compsFirstPosition + itemLenghts.compsLenght);
        }
      }
    },
    [
      compsFirstPosition,
      itemLenghts.compsLenght,
      cursor,
      itemLenghts.recentViewsLenght,
      itemLenghts.relatedAreasLenght,
      showAdvancedBar,
    ],
  );

  const arrowLeft = useCallback(
    (event: any) => {
      if (event.key === 'ArrowLeft' && showAdvancedBar) {
        if (cursor === itemLenghts.totalLenght || cursor > compsFirstPosition + itemLenghts.compsLenght - 1) {
          setCursor(compsFirstPosition);
        } else {
          setCursor(0);
        }
      }
    },
    [compsFirstPosition, cursor, itemLenghts.compsLenght, itemLenghts.totalLenght, showAdvancedBar],
  );

  const enterAction = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        if (showAdvancedBar) {
          setIsActivateOnClick(true);
        } else if (suggestedOptions.length > 0) {
          pickSuggestionItem(suggestedOptionPointedByCursor as any);
        } else {
          pickItem(optionPointedByCursor);
        }
        // give extra time to set the cursor when pressing enter key to avoid errors with the position
        setTimeout(() => {
          setCursor(0);
        }, 1000);
        setIsActivateOnClick(false);
        setShowAdvancedBar(false);
      }
    },
    [
      optionPointedByCursor,
      pickItem,
      pickSuggestionItem,
      showAdvancedBar,
      suggestedOptionPointedByCursor,
      suggestedOptions.length,
    ],
  );

  useEffect(() => {
    if (showItems || (focus && showAdvancedBar)) {
      setOverlay(true);
      setOpenNearbyAreas(false);
    } else if (!showItems && !focus && !showAdvancedBar) {
      setOverlay(false);
    }
  }, [showItems, focus, showAdvancedBar]);

  useEffect(() => {
    if (openNearbyAreas && !setOverlay()) {
      setOverlay(true);
    } else if (setOverlay()) {
      setOverlay(false);
    }
  }, [openNearbyAreas]);

  useEffect(() => {
    if (!hideItemsAfterPick && selected?.selectionHandled) {
      setShowItems(false);
    }
  }, [hideItemsAfterPick, selected?.selectionHandled]);

  useEffect(() => {
    if (!childAreasLoading && !parentAreasLoading && hasChildAreas) {
      const childAreas = childAreasData?.childAreas.map((child) => ({
        id: child.areaId,
        name: child.areaName,
        nListings: child.countActive.find((count) => count.providerId === providerId)?.countActive || 0,
      }));
      setAreasList(childAreas);
    } else {
      setAreasList(nearbyAreasData);
    }
  }, [childAreasLoading, parentAreasLoading, hasChildAreas, childAreasData, nearbyAreasData, providerId]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showPopUp) {
      timer = setTimeout(() => {
        showAccountFlowPopOverVar(false);
      }, 60000);
    }
    return () => clearTimeout(timer);
  }, [showPopUp]);

  useEffect(() => {
    if (showItems) {
      setShowAdvancedBar(false);
    }
  }, [showItems]);

  useEffect(() => {
    if (inputValue?.length) {
      setShowAdvancedBar(false);
    }
  }, [inputValue]);

  useEffect(() => {
    setIsSuggestedOpen(!isLoading && !options.length && showItems);
  }, [isLoading, options, showItems]);

  useEffect(() => {
    window.addEventListener('keydown', arrowDown);
    window.addEventListener('keydown', arrowUp);
    window.addEventListener('keydown', arrowRight);
    window.addEventListener('keydown', arrowLeft);
    window.addEventListener('keydown', enterAction);

    return () => {
      window.removeEventListener('keydown', arrowDown);
      window.removeEventListener('keydown', arrowUp);
      window.removeEventListener('keydown', arrowRight);
      window.removeEventListener('keydown', arrowLeft);
      window.removeEventListener('keydown', enterAction);
    };
  }, [arrowDown, arrowLeft, arrowRight, arrowUp, enterAction]);

  return (
    <div
      ref={autocompleteRef}
      className={clsx(styles.AutoComplete, styles.AutoComplete2, className)}
      data-guide-tour-id="autocomplete"
    >
      <div>
        <MagnifyingGlassIcon className={styles.SearchIcon} color={focus ? '#3593FC' : '#1D2129'} />
      </div>
      <input
        className={styles.Input}
        type="text"
        placeholder={placeholder || 'Enter an address, city, or post code'}
        value={inputValue}
        onChange={onChangeText}
        onFocus={toggleShowItems(true)}
        onClick={handleInputClick}
        onBlur={() => updateFocus(false, 200)}
        onMouseOut={() => updateFocus(false, 2000)}
        ref={inputRef}
        data-test-id="search-bar-input"
        data-guide-tour-id="area-search-bar"
      />

      {focus && (
        <div className={styles.clearIconContainer} onClick={clearInput}>
          <ClearIcon />
        </div>
      )}

      {!focus && !openNearbyAreas && !hideChevron && (
        <PopOver
          description={setTitle()}
          position="bottom"
          boxClassName={styles.PopOverBox}
          width={showPopUp ? 250 : 90}
          showPopover={showPopUp}
          showArrow={showPopUp}
          boxOffSetManual={37}
          disableHoverEvents={showPopUp}
        >
          <FullChevron onClick={openSearchBarResults} dataTestId="search-bar-dropdown" />
        </PopOver>
      )}

      <AutoCompleteListItems
        options={filteredOptions}
        show={showItems}
        textSearched={inputValue}
        className={styles.List}
        loading={isLoading}
        onSelectItem={pickItem}
        isSuggestedOptions={suggestedOptions.length}
        cursor={cursor}
        currentArea={currentArea}
        suggestedItems={
          <SuggestionList
            textSearched={inputValue}
            options={suggestedOptions}
            onClickItem={pickSuggestionItem}
            cursor={cursor}
          />
        }
      />

      {openNearbyAreas && areasList && (
        <AutoCompleteDropDownList
          data={areasList}
          currentArea={currentArea}
          onClickArea={pickNearbyArea}
          openNearbyAreas={openNearbyAreas}
          className={className}
          onClose={() => setOpenNearbyAreas(false)}
          hasChildAreas={hasChildAreas}
          hasParentAreas={hasParentAreas}
          childCount={(childAreasData && childAreasData.childAreas.length) || 0}
          parentAreas={parentAreasData?.parentAreas}
        />
      )}

      {showAdvancedBar && !isSuggestedOpen && isLogin && (
        <AdvancedSearchBar
          data={areasList}
          currentArea={currentArea}
          onClickArea={pickNearbyArea}
          isOpen={showAdvancedBar}
          className={className}
          onClose={() => closeSearchBarResults()}
          hasChildAreas={hasChildAreas}
          hasParentAreas={hasParentAreas}
          childCount={(childAreasData && childAreasData.childAreas.length) || 0}
          parentAreas={parentAreasData?.parentAreas}
          afterClickItem={closeSearchBarResults}
          emptyByDefault={emptyByDefault}
          cursor={cursor}
          setItemLenghts={setItemLenghts}
          isActivateOnClick={isActivateOnClick}
        />
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Areas } from '@apolloCli/queries/activeOrders';
import { UpsertCompetitorGroup } from '@hooks/useCompetitorSet';
import { MarketResponse } from '@hooks/useCustomMarket';

import { AreaList, ListNames } from './RelatedAreas/RelatedAreas';

export type AdvanceSearchResults = {
  recentViewedAreas?:
    | {
        position: number;
      }[];
  relatedResults?: AreaList[];
  myMarkets?: Areas[];
  compSets?: UpsertCompetitorGroup[];
  savedSearches?: MarketResponse[];
  position?: number;
};

export const useAdvancedSearchParameters = () => {
  const { pathname, push } = useRouter();
  const [showComps, setShowComps] = useState(true);
  const [showSavedAreas, setShowSavedAreas] = useState(true);
  const [route, setTool] = useState('historical');

  const onClickMarket = (areaId?: string | number, customMarketId?: string | number) => {
    if (areaId && customMarketId) {
      push(`/investor/${route}/[slug]`, `/investor/${route}/${areaId}?marketId=${customMarketId}`);
    } else if (areaId) {
      push(`/investor/${route}/[slug]`, `/investor/${route}/${areaId}`);
    }
  };

  const onClickComp = (comp?: UpsertCompetitorGroup) => {
    if (comp) {
      const { areaId, competitorGroupId: compId } = comp;
      push(`/investor/comps/${areaId}?compId=${compId}`);
    }
  };

  const multipleClickActions = (element: any, onClickArea: (event: AreaList) => void) => {
    switch (element?.listName) {
      case ListNames.RECENT_VIEWS:
        onClickMarket(element?.areaId);
        break;
      case ListNames.RELATED_RESULTS:
        onClickArea(element);
        break;
      case ListNames.MY_MARKETS:
        onClickMarket(element?.areaId);
        break;
      case ListNames.COMPS:
        onClickComp(element);
        break;
      case ListNames.SAVED_SEARCHES:
        onClickMarket(element.areaId, element.id);
        break;
      default:
        onClickMarket(element?.areaId);
        break;
    }
  };

  useEffect(() => {
    let tool: string;
    switch (pathname) {
      case '/investor/for-you':
        tool = 'historical';
        break;
      case '/investor/overview/[slug]':
        tool = 'overview';
        break;
      case '/investor/historical/[slug]':
        tool = 'historical';
        break;
      case '/investor/future/[slug]':
        tool = 'future';
        break;
      case '/investor/comps/[slug]':
        tool = 'comps';
        break;
      case '/investor/calculator':
        tool = 'calculator';
        break;
      case '/investor/top-properties/[slug]':
        tool = 'top-properties';
        break;
      default:
        tool = 'historical';
    }
    setTool(tool);
    if (tool === 'overview') {
      setShowSavedAreas(false);
    }
    if (tool === 'top-properties') {
      setShowComps(false);
    }
  }, [pathname]);

  return { onClickMarket, onClickComp, showComps, showSavedAreas, multipleClickActions };
};

import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { LogoDoors } from '@alltherooms/ui-library';
import { MarketResponse } from '@hooks/useCustomMarket';
import { SavedSearchIcon } from '@components/iconography/SavedSearchIcon/SavedSearchIcon';
import { ellipsis } from '@utils/ellipsis';

import { ListItem } from '../ListItem/ListItem';

import styles from './SavedMarkets.module.scss';

type Props = {
  customMarkets?: MarketResponse[];
  onClickCustomMarketItem?: (area: MarketResponse) => void;
  loading?: boolean;
  cursor?: number;
};

const ListTitle: React.FC<{ title: string; onClick: () => void }> = ({ title, onClick }) => (
  <div className={styles.ListHeader}>
    <span>{title}</span>
    <div className={styles.Link} onClick={onClick}>
      See All
      <img className={styles.Arrow} src="/images/icons/Arrow.svg" alt="arrow icon" />
    </div>
  </div>
);

export const SavedMarkets: React.FC<Props> = ({
  customMarkets,
  onClickCustomMarketItem = () => {},
  loading,
  cursor,
}: Props) => {
  const { push } = useRouter();
  const seeAllMarkets = () => {
    push('/investor/for-you');
  };

  const hasCustomMarkets = customMarkets && customMarkets.length > 0;

  return (
    <div className={styles.Container}>
      {hasCustomMarkets && (
        <div className={styles.List}>
          <ListTitle title="Saved Searches" onClick={seeAllMarkets} />
          {loading && <LogoDoors loop />}
          {!loading && (
            <>
              {customMarkets.map((area) => (
                <ListItem
                  key={area.areaId}
                  onClick={() => onClickCustomMarketItem(area)}
                  className={clsx(styles.Icon, cursor === area.position && styles.Active)}
                  dataTestId={`advanced-custom-market-${area.areaName}`}
                >
                  <SavedSearchIcon />
                  {ellipsis(area.name)}
                </ListItem>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { QueryBuilder as RecentSearchIcon } from '@mui/icons-material';
import { AdvancedSearchArea } from '@hooks/useAdvanceSearch';

import { ListItem } from '../ListItem/ListItem';

import styles from './RecentSearches.module.scss';

type Props = {
  data?: AdvancedSearchArea[];
  onClickItem?: (area?: AdvancedSearchArea) => void;
  cursor?: number;
};

export const RecentSearches: React.FC<Props> = ({ data, cursor, onClickItem = () => {} }: Props) => (
  <div className={styles.Container}>
    <span className={styles.ListHeader}>Recent Searches</span>
    {data?.map((area) => (
      <ListItem
        key={area.areaId}
        className={cursor === area.position && styles.Active}
        onClick={() => onClickItem(area)}
        dataTestId="recent-search"
      >
        <RecentSearchIcon />
        {area.text}
      </ListItem>
    ))}
  </div>
);

/* eslint-disable max-len */
import React from 'react';

export const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.71 113.39" height={20} width={20}>
    <defs />
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          className="cls-1"
          d="M45.35,55.28a9.94,9.94,0,0,0,9.93-9.93,9.94,9.94,0,0,0-9.93-9.92,9.94,9.94,0,0,0-9.92,9.92,9.94,9.94,0,0,0,9.92,9.93Zm0,46.91Q64.21,85,73.2,71.08t9-24.59q0-16.72-10.7-27.36A35.71,35.71,0,0,0,45.35,8.5,35.74,35.74,0,0,0,19.2,19.13Q8.51,29.77,8.5,46.49q0,10.62,9.22,24.59T45.35,102.19Zm0,11.2Q22.54,94,11.27,77.31T0,46.49Q0,25.22,13.68,12.61T45.35,0Q63.35,0,77,12.61T90.71,46.49q0,14.17-11.27,30.82T45.35,113.39Z"
        />
      </g>
    </g>
  </svg>
);

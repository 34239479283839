import React from 'react';
import clsx, { ClassValue } from 'clsx';
import { AutoCompleteItem } from '@components/dataEntry/AutoComplete/AutoCompleteItem/AutoCompleteItem';
import { AutoCompleteItemDisabled } from '@components/dataEntry/AutoComplete/AutoCompleteItem/AutoCompleteItemDisabled';
import { AreaInfo } from '@apolloCli/queries/areas';

import styles from './AutoComplete.module.scss';

type Payload<T> = T extends never
  ? {
      payload?: unknown;
    }
  : {
      payload: T;
    };

export type AutoCompleteOption<T = unknown> = {
  text: string;
  key?: React.Key;
  selectionHandled?: boolean;
} & Payload<T>;

type Props<PayloadType = unknown> = {
  className?: ClassValue;
  loading: boolean;
  options: AutoCompleteOption<PayloadType>[];
  show: boolean;
  textSearched: string;
  isSuggestedOptions: number;
  onSelectItem: (option: AutoCompleteOption<PayloadType>) => void;
  suggestedItems?: React.ReactNode;
  cursor?: number;
  currentArea?: AreaInfo;
};

export const AutoCompleteListItems = <P,>({
  className,
  loading,
  options,
  show,
  textSearched,
  onSelectItem,
  suggestedItems,
  isSuggestedOptions,
  cursor,
  currentArea,
}: Props<P>) => {
  if (!show || !textSearched || textSearched.length < 2 || !options) {
    return null;
  }

  const onClickItem = (text: string) => {
    const option = options.find((opt) => opt.text === text);
    if (!option) {
      throw new Error('Cannot find an autocomplete option back from its text');
    }
    onSelectItem(option);
  };

  return (
    <ul className={clsx(!loading && !options.length && styles.EmptyList, className)}>
      {!loading &&
        options.map((option) => (
          <AutoCompleteItem
            key={option.key ?? option.text}
            text={option.text}
            textSearch={textSearched}
            onClick={onClickItem}
            active={cursor === option.key}
          />
        ))}

      {loading && <AutoCompleteItemDisabled type="loading" />}

      {!loading && !options.length && (
        <AutoCompleteItemDisabled
          type="not-found"
          isSuggestedOptions={isSuggestedOptions}
          textSearched={textSearched}
          suggestedItems={suggestedItems}
          currentArea={currentArea}
        />
      )}
    </ul>
  );
};

/* eslint-disable max-len */
import React from 'react';

export const CompsIcon = () => (
  <svg viewBox="0 0 113.39 82.45" width={20} height={20}>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <g id="Capa_1-2-2" data-name="Capa 1-2">
          <path
            className="cls-1"
            d="M54.64,0h4.27l.38.09c1.21.15,2.42.24,3.61.46A33.07,33.07,0,0,1,83.18,12.89c4.32,5.41,6.13,11.79,6.46,18.6,0,.48,0,1,.07,1.43,1-.1,1.87-.21,2.78-.27a19.59,19.59,0,0,1,7.89,1c7.6,2.66,13.19,9.74,13,19.32a18.74,18.74,0,0,1-1.52,6.8,45,45,0,0,1-7.22,11.48,102.31,102.31,0,0,1-8.82,9.33l-2,1.86A126,126,0,0,1,84.6,73,59.44,59.44,0,0,1,77,62.33a128.2,128.2,0,0,1-9.67,10.51c-3.39,3.33-6.91,6.52-10.56,9.61A161.4,161.4,0,0,1,36.53,62.34c-4.25,7.91-10.44,14.11-16.89,20.11l-.22-.19c-2.28-2.26-4.59-4.47-6.8-6.79A66.85,66.85,0,0,1,4.26,64.86,28.37,28.37,0,0,1,.42,56.18c-.18-.8-.28-1.63-.42-2.45V51.45a2,2,0,0,0,.07-.26c.1-.84.15-1.69.29-2.52a18.87,18.87,0,0,1,11-14.37,19.34,19.34,0,0,1,10.54-1.57c.6.06,1.19.14,1.82.22,0-.19,0-.34,0-.49a37.61,37.61,0,0,1,.73-6.78,30.09,30.09,0,0,1,4.68-11.31A34,34,0,0,1,42.69,3,30.17,30.17,0,0,1,52.35.27Zm2.07,74.25L57,74c1.68-1.58,3.39-3.14,5-4.75A124.77,124.77,0,0,0,73.16,57a59.24,59.24,0,0,0,8.27-13.71,25.76,25.76,0,0,0,2-8,29.22,29.22,0,0,0-.5-7.75A25.73,25.73,0,0,0,68.88,9,26.41,26.41,0,0,0,51.41,6.69C42.8,8.36,36.57,13.33,32.66,21.14A28.11,28.11,0,0,0,30,34.44C30.71,45,38.17,53.15,39.43,55.5A17.26,17.26,0,0,0,41.37,58a130.78,130.78,0,0,0,9.05,10.07c2.06,2.07,4.17,4.09,6.29,6.16ZM27.9,60.47V49.59a1.59,1.59,0,0,0-.13-.52c-.51-1.14-1-2.28-1.52-3.39a1.29,1.29,0,0,0-.38-.48l-6-4a.44.44,0,0,0-.59,0l-7.71,5.11a.62.62,0,0,0-.31.59V60.07a1.58,1.58,0,0,0,0,.38h4.56v-9h7.49v9Zm62.14-9H97.6v9h4.48V46.94a.7.7,0,0,0-.21-.53c-2.66-1.8-5.35-3.57-8.05-5.36-2.09,1.38-4.18,2.78-6.26,4.18a.73.73,0,0,0-.25.35c-.54,1.18-1.06,2.35-1.57,3.54a2.13,2.13,0,0,0-.19.76V60a2.32,2.32,0,0,0,0,.45H90Z"
          />
          <path
            className="cls-1"
            d="M68.09,44.3h-6.2V32H51.63V44.3H45.41V25.68a.74.74,0,0,1,.38-.72q5.31-3.5,10.59-7a.56.56,0,0,1,.71,0c3.57,2.38,7.15,4.77,10.73,7.14a.68.68,0,0,1,.27.51q0,9.23,0,18.46C68.12,44.09,68.1,44.17,68.09,44.3Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { LogoDoors } from '@alltherooms/ui-library';
import { UpsertCompetitorGroup } from '@hooks/useCompetitorSet';
import { CompsIcon } from '@components/iconography/CompsIcon/CompsIcon';
import { ellipsis } from '@utils/ellipsis';

import { ListItem } from '../ListItem/ListItem';

import styles from './MyComps.module.scss';

type Props = {
  data?: UpsertCompetitorGroup[];
  onClickItem?: (comp?: UpsertCompetitorGroup) => void;
  loading?: boolean;
  cursor?: number;
};

export const MyComps: React.FC<Props> = ({ data, onClickItem = () => {}, loading, cursor }: Props) => {
  const { push } = useRouter();
  const seeAllComps = () => {
    push('/investor/for-you');
  };

  return (
    <div className={styles.Container} data-test-id="saved-comps">
      <div className={styles.ListHeader}>
        <span>Saved Comps</span>
        <div className={styles.Link} onClick={seeAllComps}>
          See All
          <img className={styles.Arrow} src="/images/icons/Arrow.svg" alt="arrow icon" />
        </div>
      </div>
      {data?.map((item) => (
        <ListItem
          key={item.competitorGroupId}
          onClick={() => onClickItem(item)}
          className={clsx(styles.Icon, loading && styles.Loading, cursor === item.position && styles.Active)}
        >
          {loading && <LogoDoors loop />}
          {!loading && (
            <>
              <CompsIcon />
              {ellipsis(item?.name)}
            </>
          )}
        </ListItem>
      ))}
    </div>
  );
};

import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './ListItem.module.scss';

type Props = {
  onClick: (value?: string) => void;
  children: React.ReactNode;
  className?: ClassValue;
  dataTestId?: string;
};

export const ListItem = ({ onClick, children, className, dataTestId }: Props) => (
  <span onClick={() => onClick()} className={clsx(styles.Item, className)} data-test-id={dataTestId}>
    {children}
  </span>
);

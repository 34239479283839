import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { ParentChildAreas } from '@apolloCli/queries/accountFlow';
import { LocationIcon } from '@components/iconography/LocationIcon/LocationIcon';
import { CountActive } from '@apolloCli/queries/nearbyAreas';
import { CONTACT_US_WEBAPP } from '@utils/links';

import styles from './RelatedAreas.module.scss';

export enum ListNames {
  RECENT_VIEWS = 'recentViews',
  RELATED_RESULTS = 'relatedResults',
  MY_MARKETS = 'myMarkets',
  COMPS = 'comps',
  SAVED_SEARCHES = 'savedSearches',
}

export type AreaList = {
  id: number;
  name: string;
  nListings: number;
  position?: number;
  countActive?: CountActive[];
  listName?: ListNames;
};

type Props = {
  hasChildAreas?: boolean;
  data?: AreaList[];
  lastParent?: ParentChildAreas;
  hasParentAreas?: boolean;
  parentAreas?: ParentChildAreas[];
  onClick: (value: AreaList) => void;
  areaName?: string;
  cursor?: number;
};

const formatNumber = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const RelatedAreas: React.FC<Props> = ({ data, lastParent, onClick, areaName, cursor }: Props) => {
  const sortedData = data?.sort((x, y) => {
    if ((x.id === lastParent?.areaId) === (y.id === lastParent?.areaId)) {
      return 0;
    }
    if (x) {
      return -1;
    }
    return 1;
  });

  return (
    <div className={styles.Container}>
      <div className={clsx(styles.Table)}>
        <table>
          <thead>
            <div className={styles.ListHeader}>
              <span className={styles.TitleTableLight}>Related Results</span>
              <span className={styles.NumberOfListings}># of listings</span>
            </div>
          </thead>
          <tbody className={styles.ResultsList}>
            {sortedData?.map((area) => (
              <tr
                onClick={() => onClick(area)}
                className={clsx(styles.List, cursor === area.position && styles.Active)}
                key={area.id}
              >
                <td className={styles.Label}>
                  <LocationIcon />
                  {area.name} {lastParent?.areaId === area.id && <i className={styles.Light}>- Includes {areaName}</i>}
                </td>
                <td className={styles.Value}>{formatNumber(area.nListings || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!data?.length && (
        <div className={styles.NotAreaMessage}>
          <p>
            Please use the main search-bar to navigate global markets.
            <br />
            If there is something missing, please
            <Link href={CONTACT_US_WEBAPP} target="_blank">
              let us know!
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import clsx from 'clsx';

import styles from './AutoCompleteItem.module.scss';

type Props = {
  text: string;
  textSearch: string;
  onClick: (text: string) => void;
  active?: boolean;
};

const splitText = (response: string, textSearch: string) => {
  const arrayResponse = Array.from(response);
  const arrayTextSearch = Array.from(textSearch);

  let isDifferent = false;
  return arrayResponse.reduce(
    (accum: string[], current: string, index: number) => {
      const lowercase = current.toLowerCase();
      const lowercaseSearch = arrayTextSearch[index];

      if (!isDifferent) {
        if (lowercase === lowercaseSearch) {
          // eslint-disable-next-line no-param-reassign
          accum[0] += current;
          return accum;
        }

        isDifferent = true;
      }

      // eslint-disable-next-line no-param-reassign
      accum[1] += current;

      return accum;
    },
    ['', ''],
  );
};

export const AutoCompleteItem: React.FunctionComponent<Props> = ({ text, textSearch, onClick, active }) => {
  const [searchedText, suggestionText] = splitText(text, textSearch);
  return (
    <li
      className={clsx(styles.TextContainer, active && styles.Active)}
      onClick={() => onClick(text)}
      data-test-id="search-suggestion"
    >
      {!searchedText && <span className={styles.Text}>{suggestionText}</span>}

      {searchedText && (
        <>
          <span className={styles.Text}>{searchedText}</span>
          <span className={clsx(styles.Text, styles.Suggestion)}>{suggestionText}</span>
        </>
      )}
    </li>
  );
};

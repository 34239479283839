import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useMutation, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { ADD_CUSTOM_AREA, GET_SAVED_MARKETS, DELETE_SAVED_AREA } from '@apolloCli/queries/forYou';
import { useAreaInfo } from '@hooks/useAreaInfo';
import { ListNames } from '@components/dataEntry/AdvancedSearchBar/RelatedAreas/RelatedAreas';

import { useUiFilters } from './useUiFilters';
import { useUser } from './useUser';

export type FiltersSet = {
  provider: string;
  arrangementType: string;
  instantBook: string;
  hostType: string;
  rooms: string;
  sleeps: string;
  bathrooms: string;
  lastActiveSince: string;
  adrLtm: string;
  houseRules: string;
  amenities: string;
  rating: string;
  propertyType: string;
};

export type CustomAreaResult = {
  upsertCustomMarket: {
    customMarketId: number;
    name: string;
    filters: FiltersSet;
    areaId: number;
  };
};

export type DeleteAreaResult = {
  removeCustomMarket: {
    customMarketId: number;
    result: boolean;
  };
};

export type CustomAreaInput = {
  customMarketId?: number;
  areaId?: number;
  name?: string;
  filters: FiltersSet;
};

export type DeleteAreaInput = {
  customMarketId: number;
};

export type MarketResponse = {
  id: number;
  name: string;
  areaId: number;
  areaName: string;
  filters: FiltersSet;
  position?: number;
  listName?: ListNames;
};

export type GetCustomMarkets = {
  getCustomMarkets: {
    nextDefaultName: string;
    customMarkets: MarketResponse[];
  };
};

type Args = {
  customMarketId?: number;
  getAllMarkets?: boolean;
  aid?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  isAdvanceSearch?: boolean;
};

export const useCustomMarket = ({
  customMarketId,
  getAllMarkets = false,
  aid,
  fetchPolicy = 'cache-and-network',
  isAdvanceSearch = false,
}: Args = {}) => {
  const {
    providerId,
    arrangementTypes,
    hostType,
    instantBook,
    bathroom,
    sleeps,
    rooms,
    rating,
    completeFilters: {
      filterPropertyType: propertyType,
      filterAmenitiesType: amenities,
      filterHouseRulesType: houseRules,
    },
  } = useUiFilters();
  const { areaId: areaInfoId } = useAreaInfo();
  const { isLogin } = useUser();
  const { pathname } = useRouter();
  const areaId = areaInfoId || aid;
  const skipCustomMarkets = isAdvanceSearch && pathname.includes('overview');

  const variables: CustomAreaInput = useMemo(
    () => ({
      customMarketId,
      areaId,
      filters: {
        arrangementType: arrangementTypes,
        hostType,
        instantBook,
        rooms,
        sleeps,
        bathrooms: bathroom,
        provider: providerId,
        adrLtm: '1+',
        amenities: amenities.join(','),
        houseRules: houseRules.join(','),
        lastActiveSince: '30-',
        propertyType: propertyType.join(','),
        rating,
      },
    }),
    [
      customMarketId,
      areaId,
      arrangementTypes,
      hostType,
      instantBook,
      rooms,
      sleeps,
      bathroom,
      providerId,
      houseRules,
      amenities,
      propertyType,
      rating,
    ],
  );

  const [createCustomArea, { loading, error }] = useMutation<CustomAreaResult, CustomAreaInput>(ADD_CUSTOM_AREA);
  const [updateCustomArea, { loading: updateAreaLoading, error: updateAreaError }] =
    useMutation<CustomAreaResult, CustomAreaInput>(ADD_CUSTOM_AREA);
  const [deleteArea, { loading: deleteAreaLoading, error: deleteError }] =
    useMutation<DeleteAreaResult, DeleteAreaInput>(DELETE_SAVED_AREA);

  const {
    data,
    loading: loadingCustomMarket,
    error: errorCustomMarket,
    refetch,
  } = useQuery<GetCustomMarkets>(GET_SAVED_MARKETS, {
    skip: !isLogin || skipCustomMarkets,
    variables: {
      customMarketId,
      areaId: getAllMarkets ? undefined : areaId,
    },
    fetchPolicy,
  });

  const saveCustomArea = async () => {
    const result = await createCustomArea({ variables });
    await refetch();
    return result;
  };

  const renameCustomArea = async (customMarketIdScope: number, area: number, name: string, filters: any) => {
    const result = await updateCustomArea({
      variables: { customMarketId: customMarketIdScope, name, filters, areaId: area },
    });
    await refetch();
    return result;
  };

  const deleteCustomArea = async (customMarketIdScope: number) => {
    const result = await deleteArea({ variables: { customMarketId: customMarketIdScope } });
    await refetch();
    return result;
  };

  return {
    saveCustomArea,
    renameCustomArea,
    deleteCustomArea,
    customMarketsData: data?.getCustomMarkets,
    error: error || errorCustomMarket || deleteError || updateAreaError,
    loading: loading || loadingCustomMarket || deleteAreaLoading || updateAreaLoading,
    refetchCustomMarkets: refetch,
  };
};

import React, { useEffect } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';
import { AreaInfo } from '@apolloCli/queries/areas';
import { appEvent } from '@utils/analytics';
import { CONTACT_US_WEBAPP } from '@utils/links';

import styles from './AutoCompleteItem.module.scss';

type Props = {
  type: 'loading' | 'not-found';
  textSearched?: string;
  suggestedItems?: React.ReactNode;
  isSuggestedOptions?: number;
  currentArea?: AreaInfo;
};

export const AutoCompleteItemDisabled: React.FunctionComponent<Props> = ({
  type,
  textSearched,
  suggestedItems,
  isSuggestedOptions,
  currentArea,
}) => {
  useEffect(() => {
    // execute after a brief moment of nof typing
    const timeoutId = setTimeout(() => {
      if (type === 'not-found') {
        appEvent('No Geocoded Results', {
          areaId: currentArea?.id,
          areaName: currentArea?.fullName,
        });
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [type, currentArea?.id, currentArea?.fullName]);

  return (
    <li
      className={clsx(
        styles.TextContainer,
        styles.DisabledItem,
        type === 'loading' && styles.LoadingTextContainer,
        type === 'not-found' && styles.NotFoundContainer,
      )}
    >
      {type === 'loading' && (
        <div className={styles.LoadingContainer}>
          <div className={styles.LogoContainer}>
            <LogoDoors size="m" loop />
          </div>
          <div className={styles.Text}>Looking for your area…</div>
          <div className={styles.Moment}>This process might take a moment</div>
        </div>
      )}

      {type === 'not-found' && (
        <div className={styles.NotFoundTextContainer}>
          <div className={styles.WrapperCenter}>
            <p className={styles.Line1}>
              We couldn’t find <i>{textSearched}</i> in our database
            </p>
            {isSuggestedOptions && isSuggestedOptions > 0 ? (
              <span className={styles.Line2}>But no worries! We found these areas related to your search.</span>
            ) : (
              <span className={styles.Line2}>
                It seems we don't have a polygon for this area yet, please check for areas nearby or{` `}
                <Link href={CONTACT_US_WEBAPP} target="_blank">
                  click here
                </Link>
                {` `}to inquire about a Custom Area.
              </span>
            )}
          </div>
          {suggestedItems && <div>{suggestedItems}</div>}
        </div>
      )}
    </li>
  );
};

const INVESTOR = 'investor';
const HOST = 'host';

// Investor
export const FOR_YOU = `/${INVESTOR}/for-you`;
export const HISTORICAL = `/${INVESTOR}/historical`;
export const FUTURE = `/${INVESTOR}/future`;
export const INVESTOR_COMPS = `/${INVESTOR}/comps`;
export const CALCULATOR = `/${INVESTOR}/calculator`;
export const PROPERTY_DETAILS = `/${INVESTOR}/property-details`;
export const TOP_PROPERTIES = `/${INVESTOR}/top-properties`;

// Host
export const SCORE = `/${HOST}/score`;
export const HOST_COMPS = `/${HOST}/comps`;

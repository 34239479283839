import React from 'react';
import { useRouter } from 'next/router';
import { HeartIconActive } from '@components/iconography/HeartIcon/HeartIcon';
import { Areas } from '@apolloCli/queries/activeOrders';
import { LogoDoors } from '@alltherooms/ui-library';
import { FOR_YOU } from '@utils/routes';

import { ListItem } from '../ListItem/ListItem';

import styles from './PremiumMarkets.module.scss';

type Props = {
  premiumMarkets?: Areas[];
  onClickMarketItem?: (area: Areas) => void;
  loading?: boolean;
  cursor?: number;
};

const ListTitle: React.FC<{ title: string; onClick: () => void }> = ({ title, onClick }) => (
  <div className={styles.ListHeader}>
    <span>{title}</span>
    <div className={styles.Link} onClick={onClick}>
      See All
      <img className={styles.Arrow} src="/images/icons/Arrow.svg" alt="arrow icon" />
    </div>
  </div>
);

export const PremiumMarkets: React.FC<Props> = ({
  premiumMarkets,
  cursor,
  onClickMarketItem = () => {},
  loading,
}: Props) => {
  const { push } = useRouter();
  const seeAllMarkets = () => {
    push(FOR_YOU);
  };

  const hasPremiumMarkets = !!premiumMarkets?.length;

  return (
    <div className={styles.Container}>
      {hasPremiumMarkets && (
        <div className={styles.List}>
          <ListTitle title="My Markets" onClick={seeAllMarkets} />
          {loading && <LogoDoors loop />}
          {!loading &&
            premiumMarkets?.map((area) => (
              <ListItem
                key={area.areaId}
                onClick={() => onClickMarketItem(area)}
                dataTestId={`advanced-purchased-area-${area.areaName}`}
                className={cursor === area.position && styles.Active}
              >
                <HeartIconActive />
                {area.areaName}
              </ListItem>
            ))}
        </div>
      )}
    </div>
  );
};
